<template>
  <BaseModal ref="modal" title="Activatiemails klantenportaal" max-width="tw-max-w-2xl">
    <div class="tw-flex tw-mb-4">
      <button
        type="button"
        :disabled="resendingLink"
        class="btn btn-xs btn-success"
        @click="doResendPortalActivationLink"
      >
        <template v-if="resendingLink">
          <i class="fas fa-spinner-third fa-spin" /> Mail wordt verstuurd
        </template>
        <template v-else>
          + Nieuwe activatiemail versturen
        </template>
      </button>
    </div>
    <table class="tw-table-fixed tw-w-full tw-border-0.5 tw-border-collapse">
      <thead>
        <tr class="tw-font-bold">
          <td class="tw-p-1 tw-border tw-pl-2">Omschrijving</td>
          <td class="tw-p-1 tw-border tw-pl-2">Verstuurd op</td>
          <td class="tw-p-1 tw-border tw-pl-2">
            Geldig tot
            <Tooltip class="tw-inline-block">
              <i class="fas fa-info-circle" />
              <template #popper>
                De link in de activatiemail is omwille van veiligheidsredenen voor een beperkte tijd geldig.
              </template>
            </Tooltip>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(record, index) in records" :key="record.id">
          <td class="tw-p-1 tw-border tw-pl-2">{{ record.type.name }} {{ record.created_by ? `(${record.created_by.first_name} ${record.created_by.last_name})` : '' }}</td>
          <td class="tw-p-1 tw-border tw-pl-2">{{ formatDateTime(record.created_on) }}</td>
          <td :class="['tw-p-1 tw-border tw-pl-2', index === 0 && !record.is_valid ? 'tw-bg-red-200' : '']">{{ formatDateTime(record.valid_until) }}</td>
        </tr>
      </tbody>
    </table>
  </BaseModal>
</template>

<script>
import { getActivationRecords, resendPortalActivationLink } from '@/services/contacts'
import { formatDateTime } from '@/utils/helpers'
import { Tooltip } from 'floating-vue'
import { errorModal, successModal } from '@/modalMessages'

export default {
  name: 'ActivationRecordModal',
  components: {
    Tooltip
  },
  props: {
    contactId: {
      type: [Number, String]
    }
  },
  data () {
    return {
      records: [],
      resendingLink: false
    }
  },
  methods: {
    formatDateTime,
    async fetchActivationRecords () {
      const response = await getActivationRecords({ relation: this.contactId, type__category: 'account_activation' })
      this.records = response?.data?.results
      return response
    },
    async show () {
      const response = await this.fetchActivationRecords()
      this.$refs.modal.show()
      return response
    },
    async doResendPortalActivationLink () {
      try {
        this.resendingLink = true
        const resendResponse = await resendPortalActivationLink({ contact: this.contactId })
        const fetchResponse = await this.fetchActivationRecords()
        successModal('De activatiemail voor het activeren van het klantenportaal werd opnieuw verstuurd')
        return [resendResponse, fetchResponse]
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het activeren van het klantenportaal')
      } finally {
        this.resendingLink = false
      }
    }
  }
}
</script>
